
import React from 'react'
import styles from './Header.module.sass'
import { SocialIcon } from 'react-social-icons'
import { InView } from 'react-intersection-observer'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom"

function Header() {
  return <div
  className={`${styles.Header}`}>
      <div className={styles.HeaderBackground}>
        <div className={styles.container}>
          <div className={styles.HeaderContent}>
            <Link to='/'><img src={`../../images/circle-kakapo-outline.svg`} alt='' /></Link>
            <div className={styles.textContent}>
              <Link to='/'><h2>kakapolabs</h2></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
}

export default Header
