
import React from 'react'
import styles from './Hero.module.sass'
import { Link as ScrollLink } from 'react-scroll'
import { InView } from 'react-intersection-observer'

function Hero() {
  return <div className={styles.header}>
    <div className={styles.container}>
      <img src='images/circle-kakapo-outline.svg' alt='Beaker image' className={styles.herologo} />
      <h1>kakapolabs</h1>
      <p>KakapoLabs is a multi-functional tech incubator with a focus on the humane. At Kakapo Labs our mission is to explore the boundaries between various forms of media via our creative approach to problem-solving projects, whether that be online or offline. We marry the use of digital technology with traditional and non-traditional applications to produce thought provoking, innovative products.</p>
      <p>Unlike our peers, we at Kakapo Labs are most interested in working in partnership with people with great ideas - whether our clients are business owners, marketers, project facilitators, day dreamers, content creators, bakers, artists, or developers. We want to work with experimental, innovative thinkers who are keen to solve some of humanity's greatest and tiniest problems, all whilst being open to having a little fun along the way. Based in sunny East London, our team has a vast range of technical and practical capabilities to bring your concept to life.</p>

      <ScrollLink className={styles.findOutMore} to={'video'} smooth={true}>
        <h2>Find out more</h2>
        <img src='images/down-arrow-green.png' alt='Down arrow' className={styles.downArrow} />
      </ScrollLink>
    </div>
  </div>
}

export default Hero
