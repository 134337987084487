
import React from 'react'
import styles from './Careers.module.sass'
import { InView } from 'react-intersection-observer'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom"
import projects from '../projects/projects.json'

type ProjectJson = {
  [key: string]: any
}

function Developer() {

  let { id } = useParams()
  const projectJson = projects as ProjectJson
  const projectName = id?.toString() || ' '

  return <div
    className={`${styles.container} ${styles.summaryText}`}>

    <h3>Job vacancy:</h3>
    <h1>Creator/Developer</h1>

    <div
      className={`${styles.mb30}`}>
      <p>Kakapo labs are looking for a creator/developer to join our team.  We work on a range of projects with a focus on linking real world objects and situations to online experiences - this means we get involved with online personalisation, automated manufacturing and a wide range of technologies and disciplines.   We are looking for someone to join us and work collaboratively with us on our various projects.</p>

      <p>Our approach is experimental - we often try new things - and we keep our projects small.   We’re often building new things or trying new approaches to a problem.</p>

      <p>We want to do more with automated testing on our projects, so we’re looking for someone who has experience in that area.</p>

      <p>We work a short 4 day week (Monday to Thursday, 10-4) in our lab in Limehouse, east london. We are dog friendly.</p>

      <p>This is not a remote position.</p>

    </div>

    <h2>Experience</h2>

    <div className={styles.columns}>
      <div className={styles.column}>
        <h4>Must:</h4>
        <ul>
          <li>Must have worked as a professional developer in a team (i.e. with other developers)</li>
          <li>
            Must have practical  experience of writing automated tests and using an automated testing framework
</li>
        </ul>
        <h4>Computer Technologies:
</h4>
        <p>These are useful to have some of, we doubt anyone has all of them!
</p>
        <ul>
          <li>AWS / Google cloud / Azure</li>
          <li>Javascript</li>
          <li>Typescript</li>
          <li>Node</li>
          <li>React or other similar JS frameworks (vue etc)</li>
          <li>Python</li>
          <li>GraphQL/Shopify</li>
          <li>Arduino / iOT/ embedded development</li>
          <li>Raspberry Pi</li>
          <li>Linux</li>
          <li>Git and git based deployment</li>
          <li>Interfacing with machinery: working out protocols and file formats</li>

        </ul>


        <h4>Partnerships:</h4>
        <ul>
          <li>Working with non technical people

</li>
          <li>Learning about different businesses and products</li>

        </ul>
      </div>
      <div className={styles.column}>
        <h4>Personal skills/attributes:</h4>
        <ul>
          <li>Learning new skills and techniques</li>
          <li>Problem solving</li>
          <li>Working with physical machines and products as well as software</li>
          <li>Pair/team programming</li>

        </ul>

        <h4>Making:</h4>
        <p>Our projects often involve drawing and design or making prototypes and small quantities of real objects.  Please let us know if you have practical experience of the any of following, either as a hobby or at work:</p>
        <ul>
          <li>Sewing</li>
          <li>Drawing/painting/sculpting</li>
          <li>Art and design</li>
          <li>Woodwork</li>
          <li>Crafting</li>
          <li>Electronics/soldering</li>
          <li>Printing</li>
          <li>DIY/Repairs</li>

        </ul>
      </div>
    </div>

    <a href='https://forms.gle/PPtJvU94NMBgsF4cA' target='_blank' className={`${styles.button} ${styles.primary} ${styles.mb30}`}>Apply for this role</a>

  </div>

}

export default Developer


