
import React from 'react'
import styles from './Careers.module.sass'
import {
  BrowserRouter as Router,
  Link,
} from "react-router-dom"
import { Link as ScrollLink } from 'react-scroll'
import { InView } from 'react-intersection-observer'

function Careers() {
  return <div
    className={`${styles.moduleContainer} ${styles.extendHeight}`}
    id={'careers'}>


    <h2>We're hiring!</h2>
    <p className={`${styles.mb30}`}>
      We are looking for people to join our team.
        </p>

    <div className={`${styles.jobs}`}>
      {/* <div className={`${styles.job}`}>
        <div>
          <h3>Storyteller</h3>
          <p>This role will involve producing content and sharing it online/through social as well as forming partnerships with organisations who can help sell our products.</p>
        </div>
        <Link to='/careers/storyteller' className={`${styles.button} ${styles.primary} ${styles.mt30}`}>Storyteller</Link>
      </div> */}
      <div className={`${styles.job}`}>
        <div>
          <h3>Creator/Developer</h3>
          <p>This role will involve developing interactive experiences using both online and offline technologies. We want to do more with automated testing on our projects, so we’re looking for someone who has experience in that area.</p>
        </div>
        <Link to='/careers/developer' className={`${styles.button} ${styles.primary} ${styles.mt30}`}>Developer</Link>
      </div>
    </div>









  </div>
}

export default Careers
