
import React from 'react'
import styles from './Post.module.sass'
import { InView } from 'react-intersection-observer'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom"
import posts from '../posts/posts.json'

type PostJson = {
  [key: string]: any
}

function Post() {

  let { id } = useParams()
  const postJson = posts as PostJson
  const postName = id?.toString() || ' '

  if (postJson[postName]) {
    const { title, linkUrl, buttonText, imageUrl, descriptionShort, descriptionFull } = postJson[postName]


    return <div className={`${styles.container} ${styles.postDetails}`}>


      <div
          className={`${styles.headerImage} ${styles.mb30}`}
          style={{
            backgroundImage: `url(../../${imageUrl})`
          }}
        >
        </div>


      <h1>{title}</h1>
      <div
        className={`${styles.summaryText} ${styles.mb30}`}
        dangerouslySetInnerHTML={{ __html: descriptionFull }}
      />
      <a href={linkUrl} target='_blank' className={`${styles.button} ${styles.primary} ${styles.mb30}`}>{buttonText ? buttonText : 'Visit the website'}</a>

    </div>


  } else {
    return <div
      className={`${styles.container}`}
      id={'Post'}>
      <h2>Sorry - post not found</h2>
    </div>

  }
}

export default Post



