
import React from 'react'
import styles from './Careers.module.sass'
import {
  BrowserRouter as Router,
  Link,
} from "react-router-dom"
import { Link as ScrollLink } from 'react-scroll'
import { InView } from 'react-intersection-observer'

function Careers() {
  return <InView
    threshold={0.5}
    triggerOnce={false}
  >
    {({ ref, inView }) => (<div
      ref={ref}
      className={`${inView ? styles.fadeFromLeft : styles.transparent} ${styles.moduleContainer}`}
      id={'careers'}>

      <div className={`${styles.careersBox} ${styles.mb30}`}>
        <h2>We're hiring!</h2>
        <p className={`${styles.mb30}`}>We are looking for people to join our team. View job descriptions for our current vacancies:
   </p>
        {/* <Link to='/careers/storyteller' className={`${styles.button} ${styles.primary} ${styles.mb30}`}>Storyteller</Link> */}
        <Link to='/careers/developer' className={`${styles.button} ${styles.primary} ${styles.mb30}`}>Developer</Link>

      </div>

      <ScrollLink className={`${styles.findOutMore} ${styles.onlyMob}`} to={'contact'} smooth={true}>
        <h3>Contact us</h3>
        <img src='images/down-arrow-green.png' alt='Down arrow' className={styles.downArrow} />
      </ScrollLink>
    </div>)}
  </InView>
}

export default Careers
