
import React from 'react'
import styles from './Project.module.sass'
import { InView } from 'react-intersection-observer'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom"
import projects from '../projects/projects.json'

type ProjectJson = {
  [key: string]: any
}

function Project() {

  let { id } = useParams()
  const projectJson = projects as ProjectJson
  const projectName = id?.toString() || ' '

  if (projectJson[projectName]) {
    const { title, linkUrl, buttonText, imageUrl, descriptionShort, descriptionFull } = projectJson[projectName]


    return <div className={`${styles.container} ${styles.projectDetails}`}>


      <div
          className={`${styles.headerImage} ${styles.mb30}`}
          style={{
            backgroundImage: `url(../../${imageUrl})`
          }}
        >
        </div>


      <h1>{title}</h1>
      <div
        className={`${styles.summaryText} ${styles.mb30}`}
        dangerouslySetInnerHTML={{ __html: descriptionFull }}
      />
      {linkUrl && <a href={linkUrl} target='_blank' className={`${styles.button} ${styles.primary} ${styles.mb30}`}>{buttonText ? buttonText : 'Visit the website'}</a>}

    </div>


  } else {
    return <div
      className={`${styles.container}`}
      id={'Project'}>
      <h2>Sorry - project not found</h2>
    </div>

  }
}

export default Project



