
import React from 'react'
import styles from './Careers.module.sass'
import { InView } from 'react-intersection-observer'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom"
import projects from '../projects/projects.json'

type ProjectJson = {
  [key: string]: any
}

function StoryTeller() {

  let { id } = useParams()
  const projectJson = projects as ProjectJson
  const projectName = id?.toString() || ' '

  return <div
    className={`${styles.container} ${styles.summaryText}`}>

    <h3>Job vacancy:</h3>
    <h1>Story teller</h1>

    <div
      className={`${styles.mb30}`}>
      <p> Sorry - this vacancy is now closed. </p>
    </div>



    {/* <a href='https://forms.gle/8tEqX8qqBSpTRTtK9' target='_blank' className={`${styles.button} ${styles.primary} ${styles.mb30}`}>Apply for this role</a> */}

  </div>

}

export default StoryTeller



