
import React from 'react'
import styles from './Footer.module.sass'
import { SocialIcon } from 'react-social-icons'
import { InView } from 'react-intersection-observer'

function Footer() {
  return <div
      className={`${styles.footer}`}>
      <img src='images/banner-bottom.gif' alt='' className={styles.bannerBottom} />
      <div className={styles.footerBackground}>
        <div className={styles.container}>
          <div className={styles.footerContent}>
            <div className={styles.textContent}>
              <h2>kakapolabs</h2>
              <p>© Copyright Kakapo Labs 2022. </p>
            </div>
            <div className={styles.social}>
              <SocialIcon
                url='https://twitter.com/KakapoLabs_'
                style={{ height: 50, width: 50 }}
                bgColor='transparent'
                fgColor='#aebd14' />
              <SocialIcon
                url='https://www.instagram.com/kakapo.labs/'
                style={{ height: 50, width: 50 }}
                bgColor='transparent'
                fgColor='#aebd14' />
              <SocialIcon
                url='https://www.youtube.com/channel/UCL4AhBbD5JByMA5-4ouIluw    '
                style={{ height: 50, width: 50 }}
                bgColor='transparent'
                fgColor='#aebd14' />      </div>
          </div>
        </div>
      </div>
    </div>
}

export default Footer
