
import React from 'react'
import styles from './Projects.module.sass'
import { Link as ScrollLink } from 'react-scroll'
import { InView } from 'react-intersection-observer'
import projects from './projects.json'

type ProjectTypes = {
  projectName: string
  title: string
  description: string
  linkUrl: string
  videoUrl?: string
  imageUrl?: string
}

type ProjectJson = {
  [key: string]: any
}

const Project = ({
  title,
  projectName,
  description,
  linkUrl,
  videoUrl,
  imageUrl,
}: ProjectTypes) => {


  return <div className={styles.project}>
    <div className={styles.projectDetails}>
      <div className={styles.thumbnailContainer}>

        {videoUrl && <div className={styles.video}>
          <iframe
            width='853'
            height='480'
            src={videoUrl}
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            title='Embedded youtube'
          >
          </iframe>
        </div>}

        {imageUrl && <div className={styles.image}>
        <a href={`project/${projectName}`}><img
            alt=''
            src={imageUrl}
            width={280}
          /></a>
        </div>}
      </div>

      <div className={styles.textContainer}>

        <h3>{title}</h3>
        <p>{description}</p>

        {/* <a href={linkUrl} className={`${styles.button} ${styles.primary}`}>Visit site</a> */}
      </div>
    </div>
    <a href={`project/${projectName}`} className={`${styles.button} ${styles.primary}`}>Learn more</a>

  </div>
}


function Projects() {

  const projectJson = projects as ProjectJson

  return <InView
    threshold={0}
    triggerOnce={false}
  >
    {({ ref, inView }) => (<div
      ref={ref}
      className={`${inView ? styles.fadeFromLeft : styles.transparent} ${styles.moduleContainer}`}
      id={'projects'}>
      <h2>Projects</h2>

      <div className={styles.projects}>
        {Object.keys(projectJson).map((project, index) => {
          return <Project
            projectName={project}
            title={projectJson[project].title}
            description={projectJson[project].descriptionShort}
            linkUrl={projectJson[project].linkUrl}
            imageUrl={projectJson[project].imageUrl}
          ></Project>
        })}
      </div>

      <ScrollLink className={`${styles.findOutMore} ${styles.onlyMob}`} to={'careers'} smooth={true}>
        <h3>Careers</h3>
        <img src='images/down-arrow-green.png' alt='Down arrow' className={styles.downArrow} />
      </ScrollLink>
    </div>)}
  </InView>
}

export default Projects
