
import React from 'react'
import styles from './Video.module.sass'
import { Link as ScrollLink } from 'react-scroll'
import { InView } from 'react-intersection-observer'

function Video() {
  return <InView
    threshold={0.5}
    triggerOnce={false}
  >
    {({ ref, inView }) => (<div
      ref={ref}
      className={`${inView ? styles.fadeFromLeft : styles.transparent} ${styles.moduleContainer}`}
      id={'video'}>
      <h2>Introducing Kakapo Labs</h2>
      <div className={styles.videoContainer}>
        <div className={styles.video}>
          <iframe
            width='853'
            height='480'
            src={'https://www.youtube.com/embed/RnvIgxICWlk'}
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            title='Embedded youtube'
          >
          </iframe>
        </div>
      </div>
      <ScrollLink className={`${styles.findOutMore} ${styles.onlyMob}`} to={'projects'} smooth={true}>
        <h3>Projects</h3>
        <img src='images/down-arrow-green.png' alt='Down arrow' className={styles.downArrow} />
      </ScrollLink>
    </div>)}
  </InView>
}

export default Video
