
import React from 'react'
import styles from './Contact.module.sass'
import { InView } from 'react-intersection-observer'

function Contact() {
  return <InView
    threshold={0.5}
    triggerOnce={false}
  >
    {({ ref, inView }) => (<div
      ref={ref}
      className={`${inView ? styles.fadeFromLeft : styles.transparent} ${styles.moduleContainer}`}
      id={'contact'}>
      <h2>Contact</h2>
      <p>We'd love to hear from you! Get in touch at <a href='mailto:hello@kakapolabs.co.uk'>hello@kakapolabs.co.uk</a>.</p>
    </div>)}
  </InView>
}

export default Contact



