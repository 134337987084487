import React from 'react'
import '../src/styles/global.sass'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom"

import Header from './components/header/Header'
import Hero from './components/Hero/Hero'
import Footer from './components/footer/Footer'
import Video from './components/video/Video'
import Contact from './components/contact/Contact'
import Projects from './components/projects/Projects'
import Project from './components/Project/Project'
import Posts from './components/posts/Posts'
import Post from './components/post/Post'
import Careers from './components/careers/Careers'
import CareersList from './components/careers/CareersList'
import StoryTeller from './components/careers/StoryTeller'
import Developer from './components/careers/Developer'


function App() {
  return <Router>

    <Routes>
      <Route path="/project/:id" element={
        <>
          <Header />
          <Project />
        </>
      } />


      <Route path="/blog/:id" element={
        <>
          <Header />
          <Post />
        </>
      } />

      <Route path="/careers/" element={
        <>
          <Header />
          <CareersList />
        </>
      } />

      <Route path="/careers/developer" element={
        <>
          <Header />
          <Developer />
        </>
      } />

      <Route path="/careers/storyteller" element={
        <>
          <Header />
          <StoryTeller />
        </>
      } />


      <Route path="/" element={<>
        <Hero />
        <Video />
        <Projects />
        <Careers />
        <Contact />
      </>} />
    </Routes>
    <Footer />
  </Router>
}

export default App
